const API_BASE_URL = 'https://spring-auth-server-yigit.herokuapp.com'
const WEB_URL = 'https://react-spring-auth.welldoneworks.com'
const LOCAL_URL = 'http://localhost:3000'
const OAUTH2_REDIRECT_URI = WEB_URL + '/oauth2/redirect'

const GOOGLE_AUTH_URL = API_BASE_URL + '/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI;
const FACEBOOK_AUTH_URL = API_BASE_URL + '/oauth2/authorize/facebook?redirect_uri=' + OAUTH2_REDIRECT_URI;

module.exports = {
    GOOGLE_AUTH_URL,
    FACEBOOK_AUTH_URL,
    API_BASE_URL
}
